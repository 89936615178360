@if (!isLoading) {
  <div class="comments">
    @if (isSaving) {
      <omt-spinner></omt-spinner>
    }
    <div class="section-header" fxLayout="row" fxLayoutAlign="start center">
      <i class="fas fa-comment-alt"></i>
      <span class="section-title">{{ 'TICKET.COMMENTS' | translate }}</span>
    </div>

    <div class="section-content p-1">
      <div class="comment new-comment m-1" fxLayout="column" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="center center">
          <div class="w-100-p" fxLayout="column" fxLayoutGap="1rem">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <omt-avatar [avatarUrl]="currentUser.avatarUrl" size="32"></omt-avatar>
              <div fxLayout="column">
                <div class="comment-member-name">
                  {{ currentUser.personal.lastName + ', ' + currentUser.personal.firstName }}
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <ng-select fxFlex="0 0 250px" [(ngModel)]="newComment.type" [items]="commentTypes" [clearable]="true" bindLabel="name" bindValue="value"
                         [placeholder]="'TICKET.COMMENT_TYPE_PLACEHOLDER' | translate"></ng-select>

              <div fxLayout="row" fxLayoutGap="10px">
                <button class="toggle-button" [ngClass]="{active: !newComment.external}" (click)="newComment.external = false">Intern</button>
                <button class="toggle-button" [ngClass]="{active: newComment.external}" (click)="newComment.external = true">Extern</button>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <omt-editor [(value)]="newComment.text"
                          [editMode]="true"
                          class="rounded"
                          fxFlex
                          [enableMentions]="true"/>
              <omt-save-button color="accent"
                               type="mini-fab"
                               [disabled]="disabled"
                               (click)="addComment()"
                               faIcon="fas fa-plus"
                               [isLoading]="isSaving"></omt-save-button>
            </div>
          </div>
        </div>
      </div>
      @for (comment of comments; track comment.id) {
        <div class="comment p-1" fxLayout="column" fxLayoutGap="1rem">
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <omt-avatar [avatarUrl]="comment.userAvatar" size="32"></omt-avatar>
              <div fxLayout="column">
                <div class="comment-member-name">
                  {{ getCreatorName(comment) }}
                  @if (comment.customerUser?.customerName) {
                    <span> {{ 'TICKET.FROM' | translate | lowercase }} {{ comment.customerUser.customerName }}</span>
                  }
                </div>
                <div class="comment-time secondary-text">{{ comment.createdAt | date: 'dd.MM.yyyy, HH:mm:ss' }} | {{ comment.external ? 'extern' : 'intern' }}</div>
              </div>
            </div>
            <div class="badge badge-secondary xl">
              @switch (comment.type) {
                @case (commentType.VisitReport) {
                  {{ 'TICKET.VISIT_REPORT' | translate }}
                }
                @case (commentType.Note) {
                  {{ 'TICKET.NOTE' | translate }}
                }
                @case (commentType.Task) {
                  {{ 'TICKET.TASK' | translate }}
                }
                @case (commentType.Resubmission) {
                  {{ 'TICKET.RESUBMISSION' | translate }}
                }
                @case (commentType.PhoneEntry) {
                  {{ 'TICKET.PHONE_ENTRY' | translate }}
                }
                @default {

                }
              }
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <omt-editor class="comment-bubble" [value]="comment.text | mention: users" fxFlex="95"></omt-editor>
            @if (currentUser.id === comment.user?.id) {
              <omt-delete-icon
                (delete)="delete(comment.id)"
                [deleteDialogData]="dialogData"
                [matTooltip]="'GLOBAL.DELETE' | translate"
              ></omt-delete-icon>
            }
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="p-5">
    <omt-spinner></omt-spinner>
  </div>
}
