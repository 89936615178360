import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {DialogService} from '@core/services/global/dialog.service';
import {switchMap} from 'rxjs/operators';
import {TicketTemplateHttpService} from '@management/tickets/template/ticket-template-http.service';
import {TicketTemplateModel} from '@management/tickets/template/ticket.template.model';


@Component({
    selector: 'omt-ticket-template-dropdown',
    templateUrl: './ticket-template.component.html',
    styleUrls: ['./ticket-template.component.scss']
})
export class TicketTemplateComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    selectedTemplate: TicketTemplateModel;
    templates: TicketTemplateModel[];
    isLoading = true;

    @Output()
    ticketTemplate = new EventEmitter<TicketTemplateModel>();

    /*--------------------------------------------------------------
    # Lifecycle
    --------------------------------------------------------------*/
    constructor(private readonly templateService: TicketTemplateHttpService,
                private readonly dialogService: DialogService) {
    }

    ngOnInit(): void {
        this.loadTemplates();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*--------------------------------------------------------------
    # Load
    --------------------------------------------------------------*/

    private loadTemplates(): void {
        this.isLoading = true;
        this.subs.sink = this.templateService.getTicketTemplates().subscribe((data: TicketTemplateModel[]) => {
            this.templates = [...data];
            this.isLoading = false;
        });
    }

    /*--------------------------------------------------------------
    # Actions
    --------------------------------------------------------------*/

    onChangeTemplate(): void {
        if (!this.selectedTemplate) {
            this.ticketTemplate.emit(new TicketTemplateModel());
            return;
        }

        this.ticketTemplate.emit(this.selectedTemplate);
    }

    openDialog(): void {
        const dialogRef = this.dialogService.ticketTemplateDialog();

        this.subs.sink = dialogRef.componentInstance.templateAdded.pipe(switchMap((x: TicketTemplateModel) =>
            this.templateService.createTicketTemplate(x))).subscribe((x) => {
            this.templates = [...this.templates, x];
            this.selectedTemplate = {...new TicketTemplateModel(), ...x};
            this.onChangeTemplate();
        });
    }
}
