<mat-card class="detail-card p-0 rounded">
  <div class="section-header">
    <i class="fas fa-money-bill-transfer"></i>
    {{ 'TICKET.SALES_INFOS' | translate }}
  </div>
  <mat-card-content class="p-1" fxLayout="column" fxLayoutGap="15px">
    <div class="progress w-100-p mat-elevation-z1">
      <div
        class="progress-bar cursor-pointer"
        role="progressbar"
        [style.width.%]="ticket.salesStage"
        [matTooltip]="'TICKET.SALES_STAGE' | translate"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div fxLayout="column" fxLayoutGap="1rem">
      <omt-form-element direction="row" [label]="'TICKET.SALES_STAGE' | translate" labelFlex="30">
        @if (state.editMode) {
          <ng-select [(ngModel)]="ticket.salesStage"
                     (ngModelChange)="changeSalesStage($event)"
                     [items]="salesStages"
                     [clearable]="true"
                     bindLabel="name"
                     bindValue="value"></ng-select>
        } @else {
          <span>{{ ticket.salesStage ? (ticket.salesStage + '%') : ('' | empty) }}</span>
        }
      </omt-form-element>

      <omt-form-element direction="row" [label]="'TICKET.SALES_STATUS' | translate" labelFlex="30">
        @if (state.editMode) {
          <ng-select [(ngModel)]="ticket.salesStatus" [items]="salesStatus" [clearable]="true" bindLabel="name"
                     bindValue="value"></ng-select>
        } @else {
          <span>
            @switch (ticket.salesStatus) {
              @case (0) {
                {{ 'TICKET.SALES_CHANCE_OPEN' | translate }}
              }
              @case (1) {
                {{ 'TICKET.SALES_CHANCE_LOST' | translate }}
              }
              @case (2) {
                {{ 'TICKET.SALES_CHANCE_ACQUIRED' | translate }}
              }
              @default {
                {{ '' | empty }}
              }
            }
          </span>
        }
      </omt-form-element>

      <omt-form-element direction="row" [label]="'TICKET.UNWEIGHTED_SALES_CHANCE' | translate" labelFlex="30">
        @if (!state.editMode) {
          <span>{{ ticket.unweightedSalesChance | currency: '€' | empty }}</span>
        } @else {
          <input class="redesign" type="number" [(ngModel)]="ticket.unweightedSalesChance" (ngModelChange)="calculateWeightedSalesChance()"/>
        }
      </omt-form-element>

      <omt-form-element direction="row" [label]="'TICKET.WEIGHTED_SALES_CHANCE' | translate" labelFlex="30">
        <strong>{{ ticket.weightedSalesChance | currency: '€' | empty }}</strong>
      </omt-form-element>
    </div>
  </mat-card-content>
</mat-card>
