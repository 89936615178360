@if (state.isLoading) {
  <omt-spinner></omt-spinner>
}

@if (ticket) {
  <div class="page-layout simple" id="tickets">
    <ng-container *headerActions>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        @if (ticket.id) {
          <omt-favorite-button buttonType="mini-fab" [entityId]="ticket.id" type="ticket"></omt-favorite-button>
        }
        @if (ticket.id && roles.ticketPlayPause && !state.isCurrentTicket) {
          <button
            (click)="startCurrentTicket()"
            [matTooltip]="'WORK_LOG.START' | translate"
            [disabled]="ticket.maximumTime > 0 && timeTrackingData.timeTrackingProgress >= 100"
            mat-mini-fab
            color="primary">
            <i class="fas fa-play"></i>
          </button>
        }
        @if (ticket.id && roles.ticketPlayPause && state.isCurrentTicket) {
          <button (click)="pauseCurrentTicket()"
                  [matTooltip]="'WORK_LOG.PAUSE' | translate" mat-mini-fab
                  color="primary">
            <i class="fas fa-pause"></i>
          </button>
        }
        @if (ticket.id && roles.ticketPlayPause && state.isCurrentTicket) {
          <button (click)="stopCurrentTicket()"
                  [matTooltip]="'WORK_LOG.STOP' | translate" mat-mini-fab color="primary">
            <i class="fas fa-stop"></i>
          </button>
        }
        @if (!state.templateMode) {
          <button
            mat-mini-fab
            color="primary"
            type="button"
            (click)="workLogDialog(ticket.id)"
            [disabled]="(ticket.maximumTime > 0 && timeTrackingData.timeTrackingProgress >= 100) || !roles.ticketsArbeitszeitBuchen"
            [matTooltip]="'WORK_LOG.ADD_WORK_LOG' | translate">
            <i class="fas fa-plus"></i>
          </button>
        }
        @if (ticket.id) {
          <button mat-mini-fab color="primary" (click)="cloneTicket()" [matTooltip]="'TICKET.COPY' | translate">
            <i class="fas fa-clone"></i>
          </button>
        }
        @if (!state.templateMode && roles.ticketsBearbeiten && roles.ticketVerwaltung) {
          <omt-save-button
            type="mini-fab"
            [isLoading]="state.isSaving"
            (click)="saveAsTemplate()"
            faIcon="fas fa-copy"
            [matTooltip]="'WAREHOUSE.SAVE_AS_TEMPLATE' | translate">
          </omt-save-button>
        }
        @if (ticket.id) {
          <button mat-mini-fab color="primary" type="button" (click)="openReportDialog()" [matTooltip]="'TICKET.EXPORT_TICKET' | translate">
            <i class="fas fa-file-pdf"></i>
          </button>
        }
        @if (state.editMode && state.creationMode && roles.ticketsErstellen) {
          <omt-save-button
            type="mini-fab"
            [isLoading]="state.isSaving"
            (click)="createTicket()"
            [matTooltip]="'GLOBAL.SAVE' | translate">
          </omt-save-button>
        }
        @if (state.canDelete) {
          <button
            mat-mini-fab
            color="primary"
            [matTooltip]="'GLOBAL.DELETE' | translate"
            type="button"
            (click)="deleteTicket()">
            <i class="fa fa-trash"></i>
          </button>
        }
        @if (state.canCancelEditMode) {
          <button mat-mini-fab color="primary" type="button" (click)="toggleEditMode()">
            @if (!state.editMode) {
              <i class="fa fa-pen" [matTooltip]="'GLOBAL.EDIT' | translate"></i>
            }
            @if (state.editMode) {
              <i class="fa fa-times" [matTooltip]="'GLOBAL.CANCEL' | translate"></i>
            }
          </button>
        }
        @if (state.editMode && roles.ticketsBearbeiten && !state.creationMode) {
          <omt-save-button
            type="mini-fab"
            [isLoading]="state.isSaving"
            (click)="updateTicket()"
            [matTooltip]="'GLOBAL.UPDATE' | translate">
          </omt-save-button>
        }
      </div>
    </ng-container>
    <div class="content-spacer" ngClass.xs="p-0">
      @if (state.templateMode) {
        <omt-message-box infoLevel="info">{{ 'TICKET.TEMPLATE_INFOTEXT' | translate }}</omt-message-box>
      }
      <div class="ticket-detail-container" fxLayout="row" fxLayoutGap="2rem" fxLayout.lt-lg="column">
        <div fxLayout="column" fxFlex="60" fxFlex.xl="65" fxLayoutGap="2rem">
          <omt-ticket-general [state]="state"
                              [ticket]="ticket"
                              [template]="template$ | async"
                              [businessUnits]="businessUnits"
                              [settings]="settings"
                              [(boardsToLink)]="boardsToLink"
                              [linkedBoards]="linkedBoards$ | async"
                              [timeTrackingData]="timeTrackingData"
                              (timeChange)="setTime($event.timeStr, $event.field)"
                              (estimatedTimeChange)="refreshTimeTrackingData()"></omt-ticket-general>
          @if (!state.templateMode) {
            <omt-ticket-worklogs [state]="state" [ticket]="ticket" (updatedWorkLogs)="refreshData()"></omt-ticket-worklogs>
          }
          <omt-ticket-todos [state]="state" [ticket]="ticket"></omt-ticket-todos>
          @if (!state.templateMode && ticket.id) {
            <omt-ticket-activities [state]="state" [ticket]="ticket"></omt-ticket-activities>
          }
        </div>
        <div fxLayout="column" fxFlex="40" fxFlex.xl="35" fxLayoutGap="2rem">
          <omt-ticket-assignees [state]="state" [ticket]="ticket" (takeResponsibility)="takeResponsibility()"
                                (assigneeChange)="changeAssignee($event)"></omt-ticket-assignees>
          @if (!state.templateMode) {
            <omt-ticket-dates [state]="state" [ticket]="ticket"></omt-ticket-dates>
          }
          @if (!state.templateMode) {
            <omt-ticket-time-tracking [state]="state"
                                      [ticket]="ticket"
                                      [timeTrackingData]="timeTrackingData"
                                      (timeChange)="setTime($event.timeStr, $event.field)"></omt-ticket-time-tracking>
          }
          @if (!state.templateMode) {
            <omt-ticket-sales-infos [state]="state" [ticket]="ticket"></omt-ticket-sales-infos>
          }
          @if (!state.templateMode) {
            <omt-ticket-budget [state]="state" [ticket]="ticket" [budgetInfo]="budgetInfo$ | async"></omt-ticket-budget>
          }
          @if (!state.templateMode) {
            <omt-ticket-attachments [state]="state" [ticket]="ticket" (attachmentAdded)="addAttachments($event)"></omt-ticket-attachments>
          }
        </div>
      </div>
    </div>
  </div>
}
