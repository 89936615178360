<div
  class="status status-small cursor-pointer"
  fxLayout="row" fxLayoutAlign="space-between center"
  style="height: 40px;"
  [matMenuTriggerFor]="menu"
  [ngClass]="{
                        new: selectedStatus.id === 1,
                        iProgress: selectedStatus.id === 2,
                        done: selectedStatus.id === 4,
                        canceld: selectedStatus.id === 3,
                        unsuccessful: selectedStatus.id === 5,
                        archived: selectedStatus.id === 6
                        }">
  <span class="ml-1">
    {{ selectedStatus.name }}
  </span>

  <mat-icon class="s-20">keyboard_arrow_down</mat-icon>
</div>

<mat-menu #menu="matMenu">
  @for (item of states; track item) {
    <div
      mat-menu-item
      fxLayout="row" fxLayoutAlign="start center"
      (click)="selectStatus(item)">
      <span>{{ item.name }}</span>
    </div>
  }
</mat-menu>



