import {Component, Input} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {SalesStage, TicketModel} from '@ticket/models';
import {MatLegacyCardModule} from '@angular/material/legacy-card';
import {TranslateModule} from '@ngx-translate/core';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyTooltipModule} from '@angular/material/legacy-tooltip';
import {CoreModule} from '@core/core.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {FormInputModule} from '@core/components/form-input/form-input.module';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'omt-ticket-sales-infos',
    standalone: true,
    imports: [
        MatLegacyCardModule,
        TranslateModule,
        FlexModule,
        MatLegacyTooltipModule,
        CoreModule,
        NgSelectModule,
        FormsModule,
        FormInputModule,
        CurrencyPipe
    ],
    templateUrl: './ticket-sales-infos.component.html',
    styleUrl: './ticket-sales-infos.component.scss'
})
export class TicketSalesInfosComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;

    salesStatus = [
        {name: 'Offen', value: 0},
        {name: 'Verloren', value: 1},
        {name: 'Gewonnen', value: 2}
    ];

    salesStages = [
        {name: '10%', value: 10},
        {name: '20%', value: 20},
        {name: '40%', value: 40},
        {name: '60%', value: 60},
        {name: '80%', value: 80},
        {name: '90%', value: 90},
        {name: '100%', value: 100}
    ];

    changeSalesStage(stage: SalesStage): void {
        this.ticket.salesStage = stage;
        this.calculateWeightedSalesChance();
    }

    calculateWeightedSalesChance() {
        if (this.ticket.salesStage) {
            this.ticket.weightedSalesChance = this.ticket.salesStage * this.ticket.unweightedSalesChance / 100;
        } else {
            this.ticket.weightedSalesChance = null;
        }
    }
}
